import { useMachine } from '@xstate/react';
import classNames from 'classnames';
import { ChangeEvent, ChangeEventHandler, FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Destination, Region } from '../../business-logic/models/CoverConfig';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Checkbox from '../../components/form/checkbox/Checkbox';
import Fieldset from '../../components/form/fieldset/Fieldset';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import roamingDestinationsContent from '../../content/ui/screens/select-destinations/selectDestinations';
import checkAgeEligibility from '../../hoc/check-age-eligibility/checkAgeEligibility';
import withContent from '../../hoc/with-content/withContent';
import useCoverConfig from '../../hooks/useCoverConfig';
import usePathParams from '../../hooks/usePathParams';
import common from '../../strings/common';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import { SelectDestinationsMachineContextTypes } from './select-destinations-machine/context/selectDestinationsMachineContext';
import SelectDestinationsMachine from './select-destinations-machine/selectDestinationsMachine';

import './SelectDestinations.scss';

const contentMap = {
    heading: 'ui.heading',
};

interface SelectDestinationsProps {
    content: Record<keyof typeof contentMap, string>;
}

const SelectDestinations: FC<SelectDestinationsProps> = ({ content }) => {
    const history = useHistory();
    const location = useLocation<PurchaseState>();
    const { selectedProductGroup: selectedProductGroupId, setSelectedProductGroupParam } = usePathParams();
    const chosenProductGroupId = selectedProductGroupId as string;
    const destinations = useCoverConfig(location.state?.selectedProductOption?.representedByCoverCode)?.destinations;

    const [state, send] = useMachine(SelectDestinationsMachine, {
        context: {
            selectedProductGrouping: chosenProductGroupId,
        },
        actions: {
            redirectToSelectDates: (ctx: SelectDestinationsMachineContextTypes) => {
                // one destination chosen + only one region present
                // and region name === destination name

                const destination =
                    destinations
                        ?.find((item: Destination) => item.destination === ctx.chosenDestinations[0])
                        ?.regions.find((item: Region) => item.region === ctx.chosenDestinations[0]) ?? null;
                if (!destination) {
                    history.goBack();
                }
                history.push({
                    pathname: setSelectedProductGroupParam(Routes.SELECT_DATES, chosenProductGroupId),
                    state: {
                        selectedProductGrouping: chosenProductGroupId,
                        selectedProductOption: ctx.selectedProductOption,
                        coverStartDates: [],
                        destination: {
                            destinations: state.context.chosenDestinations,
                            startingDestination: state.context.chosenDestinations[0],
                            startingRegion: destination?.region,
                            timezone: destination?.timezone,
                        },
                    },
                });
            },
            redirectToChooseStartingRegion: (ctx) => {
                history.push({
                    pathname: setSelectedProductGroupParam(Routes.SELECT_STARTING_REGION, chosenProductGroupId),
                    state: {
                        selectedProductGrouping: chosenProductGroupId,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: state.context.chosenDestinations,
                            startingDestination: state.context.chosenDestinations[0],
                            startingRegion: '',
                            timezone: '',
                        },
                    },
                });
            },
            redirectToChooseStartingDestination: (ctx) => {
                history.push({
                    pathname: setSelectedProductGroupParam(Routes.SELECT_STARTING_DESTINATION, chosenProductGroupId),
                    state: {
                        selectedProductGrouping: chosenProductGroupId,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: state.context.chosenDestinations,
                            startingDestination: '',
                            startingRegion: '',
                            timezone: '',
                        },
                    },
                });
            },
        },
    });

    useEffect(() => {
        if (location.state === undefined || !location.state.selectedProductOption) {
            history.push(Routes.SELECT_COVER);
        }
        if (!!destinations && destinations.length > 0 && !!location.state.selectedProductOption) {
            send({
                type: 'CONTEXT_INITIALISED',
                data: {
                    selectedProductOption: location.state.selectedProductOption,
                    destinations,
                },
            });
        }
    }, [send, state.context, destinations, selectedProductGroupId, location.state, history]);

    const selectDestinations = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send({ type: 'CONTINUE' });
    };

    const renderCheckbox = (destination: string, onChange: ChangeEventHandler<HTMLInputElement>, checked: boolean) => {
        return (
            <Checkbox
                id={destination}
                key={destination}
                name="covers"
                className={classNames('checkbox--button-style', checked && 'checked')}
                label={<span className="select-destinations__option__label">{destination}</span>}
                onChange={onChange}
                checked={checked}
            />
        );
    };

    const renderDestinationCheckbox = (chosenLocation: Destination) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                send({
                    type: 'SELECTED_DESTINATIONS',
                    data: [...state.context.chosenDestinations, chosenLocation.destination],
                });
            } else {
                send({
                    type: 'SELECTED_DESTINATIONS',
                    data: state.context.chosenDestinations.filter((item: any) => item !== chosenLocation.destination),
                });
            }
        };

        const checked = state.context.chosenDestinations.some((item: string) => item === chosenLocation.destination);

        return renderCheckbox(chosenLocation.destination, onChange, checked);
    };
    return (
        <Layout title={content.heading} showBackButton showLoading={state.hasTag('loading')}>
            {(!destinations || destinations.length === 0) && !state.hasTag('loading') && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            <form onSubmit={selectDestinations}>
                <Fieldset legend={content.heading} visuallyHideLegend className="select-destinations__list">
                    {!state.hasTag('loading') &&
                        destinations?.map((destination: Destination) => (
                            <div key={destination.destination}>{renderDestinationCheckbox(destination)}</div>
                        ))}
                </Fieldset>
                <Sticky>
                    <Button
                        width="full"
                        type="submit"
                        variant="primary"
                        label={common.continue}
                        className="select-destinations__cta"
                        disabled={!!state.context.chosenDestinations.length === false}
                    />
                </Sticky>
            </form>
        </Layout>
    );
};

export default checkAgeEligibility(withContent(SelectDestinations, contentMap, roamingDestinationsContent));
